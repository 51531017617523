import { DateUtils, OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderDeliveryMethodResume = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()
  const method = t(`delivery_methods.${orderSummary.delivery_method}`).toLowerCase()
  const processedAt = DateUtils.calendar(new Date(orderSummary.process_timestamp)).toLowerCase()

  const _getDeliveryMethodResumeSentence = (): string => {
    let label: string = method

    if (orderSummary.delivery_method === 'table') {
      if (orderSummary.custom_delivery_pickup_method) {
        label += ` ${orderSummary.custom_delivery_pickup_method}`
      }

      if (orderSummary.table_number) {
        label += ` ${orderSummary.table_number}`
      }
    } else if (orderSummary.delivery_method === 'delivery') {
      label += ` ${orderSummary.delivery_street}, ${orderSummary.delivery_street_nr} ${orderSummary.delivery_zipcode} ${orderSummary.delivery_locality}`
    }

    return `${label} ${processedAt}`
  }

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.method')}
      </Typography>

      <Typography variant='h6'>
        {_getDeliveryMethodResumeSentence()}
      </Typography>
    </Box>
  )
}

export default OrderDeliveryMethodResume
