import { Box, Container, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import Title from '~/src/components/mui-wrappers/Title'
import GoShoppingButton from '~/src/components/navigation/GoShoppingButton'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const NoOrderFound = () => {
  const { t } = useTranslations()
  const theme = useCustomMuiTheme()

  return (
    <Container sx={{
      width: '30%',
      py: 4,
      [theme.breakpoints.down('lg')]: {
        width: '30%'
      },
      [theme.breakpoints.down('md')]: {
        width: '80%'
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }}>
      <Title variant='h4'>
        {t('orders.no_orders_yet')}
      </Title>
      <Typography sx={{ mt: 2 }}>
        {t('orders.no_orders_found')}
      </Typography>
      <Box sx={{
        mt: 3
      }}>
        <GoShoppingButton />
      </Box>
    </Container>
  )
}

export default NoOrderFound
