import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import OrderReferenceNumber from '~/src/components/orders/OrderReferenceNumber'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderReference = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.order_reference')}
      </Typography>
      <Typography variant='h6'>
        {orderSummary.reference}
      </Typography>
      <OrderReferenceNumber orderSummary={orderSummary} />
    </Box>
  )
}

export default OrderReference
