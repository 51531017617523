import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import Price from '~/src/components/Price'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
  includePaymentMethod?: boolean,
}

const OrderTotal = ({ orderSummary, includePaymentMethod = true, ...boxProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.order_total')}
      </Typography>
      <Typography variant='h6'>
        <Price price={Number(orderSummary.total_price)} />
      </Typography>
      {includePaymentMethod && (
        <Typography variant='body1'>
          {`${t('orders.payment_method')}: ${orderSummary.payment_method.name}`}
        </Typography>
      )}
    </Box>
  )
}

export default OrderTotal
