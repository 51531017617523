import { Ingredient, OrderDetail, OrderSummary, ProductOption } from '@eo-storefronts/eo-core'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CartProduct } from '~/src/types/CartProduct'
import { CART_OF_FIRM_SELECTOR, CART_STATE, CartModifierGroupStateInterface, CartState } from '~/src/stores/cart'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useSnackbar } from 'notistack'
import { useTranslations } from '~/src/hooks/useTranslations'
import useDialog from '~/src/hooks/useDialog'
import OverridingCartModal from '~/src/components/cart/overriding-cart-modal'

interface ReturnsType {
  orderAgain(order: OrderSummary): void,
}

const useOrderAgain = (): ReturnsType => {
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const { enqueueSnackbar } = useSnackbar()
  const { resolve } = useFirmPathResolver()
  const { present } = useDialog()
  const { addModifierToGroup, setModifierToGroup }  = useCartModifierGroupsStateService()
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const firm = useEoValue(FIRM_SELECTOR)
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const setCart = useSetEoState(CART_STATE)

  const _updateCartAndRedirect = (cartProducts: CartProduct[]) => {
    if (!firm) {
      return enqueueSnackbar({
        variant: 'error',
        message: t('errors.default_error')
      })
    }

    setCart((state: CartState) => ({
      ...state,
      [firm.id]: cartProducts
    }))

    enqueueSnackbar({
      variant: 'success',
      message: t('cart.updated')
    })

    push(resolve(RoutesEnum.FIRM_HOME))
  }

  const orderAgain = (order: OrderSummary) => {
    let cartProducts: CartProduct[] = []

    order.order_details.forEach((orderDetail: OrderDetail) => {
      if (!orderDetail.product?.id) {
        return
      }

      let cartModifierGroups: CartModifierGroupStateInterface[] = []

      ;(orderDetail.product_options || []).forEach((option: ProductOption) => {
        cartModifierGroups = setModifierToGroup(
          cartModifierGroups,
          modifierGroups[
            `${orderDetail.product!.id}-${option.product_option_group.id}`
          ],
          modifiers[
            `${orderDetail.product!.id}-${option.product_option_group.id}-${option.product_option_group_option.id}`
          ]
        )
      })

      orderDetail.ingredients.forEach((ingredient: Ingredient) => {
        cartModifierGroups = addModifierToGroup(
          cartModifierGroups,
          modifierGroups[
            `${orderDetail.product!.id}-${ingredient.ingredient_group_id || 0}`
          ],
          modifiers[
            `${orderDetail.product!.id}-${ingredient.ingredient_group_id || 0}-${ingredient.id}`
          ],
          ingredient.quantity || 1
        )
      })

      cartProducts = [
        ...cartProducts,
        {
          id: orderDetail.product.id,
          quantity: orderDetail.quantity,
          comment: orderDetail.comment,
          modifierGroups: cartModifierGroups,
          is_loyalty: false,
          is_coupon: false
        }
      ]

      if (cart.length) {
        return present(
          OverridingCartModal,
          'overriding-cart-dialog',
          {
            onClose: (confirm: boolean): void => {
              if (!confirm) {
                return
              }

              _updateCartAndRedirect(cartProducts)
            }
          }
        )
      }

      _updateCartAndRedirect(cartProducts)
    })
  }

  return { orderAgain }
}

export default useOrderAgain
