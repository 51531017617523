import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import Title from '~/src/components/mui-wrappers/Title'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import useDialog from '~/src/hooks/useDialog'
import { useTranslations } from '~/src/hooks/useTranslations'

export interface OverridingCartModalProps {
  onClose(confirm: boolean): void,
}

const OverridingCartModal = ({ onClose }: OverridingCartModalProps) => {
  const { t } = useTranslations()
  const [ openByDefault, setOpenByDefault ] = useState<boolean>(true)
  const { dismiss } = useDialog()

  const _handleKeepCart = () => {
    onClose(false)
    setOpenByDefault(false)
    dismiss('overriding-cart-dialog')
  }

  const _handleWipeCart = () => {
    onClose(true)
    setOpenByDefault(false)
    dismiss('overriding-cart-dialog')
  }

  return (
    <ResponsiveDialog
      openByDefault={openByDefault}
      name={'overriding-cart-dialog'}
      persistent
      Title={<Title>{t('cart.already_items_modal.title')}</Title>}
      Actions={(
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant='contained' onClick={_handleKeepCart}>{t('cart.already_items_modal.keep')}</Button>
          <Button variant='contained' onClick={_handleWipeCart}>{t('cart.already_items_modal.wipe')}</Button>
        </Box>
      )}
    >
      <Typography>{t('cart.already_items_modal.text')}</Typography>
    </ResponsiveDialog>
  )
}

export default OverridingCartModal
