import { OrderSummary } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface Props {
  orderSummary: OrderSummary,
}

const OrderReferenceNumber = ({ orderSummary }: Props) => {
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)

  if (firm?.settings.order_reference_method !== 'sequence' || !orderSummary.number) {
    return null
  }

  return (
    <>
      <Typography variant='body1'>
        {t('orders.order_number')}
      </Typography>
      <Typography variant='h6'>
        {orderSummary.number}
      </Typography>
    </>
  )
}

export default OrderReferenceNumber
